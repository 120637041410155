import React from 'react'

export default function Instruction() {
  return (
    <div className="flex flex-col w-full px-4 py-4 bg-white rounded-2xl md:px-8">
      <h1 className="text-2xl font-semibold">Інструкція щодо використання додатку</h1>
      <div className="max-w-2xl mx-auto">
        <p className="font-mono">
          <span>
            1. Почніть вводити Ваше прізвище — Ви побачите один чи декілька варіантів — оберіть
            потрібні Вам варіанти
          </span>
          <img
            className="max-w-2xl mx-auto mt-4 rounded"
            src={`${process.env.PUBLIC_URL}/instructions/5UiKh2ERd.png`}
            alt="малюнок 1"
          />
          <span className="text-sm">
            варіантів може бути декілька через порушення шаблону (пропущено пробіл, крапку, тощо)
          </span>
        </p>
        <p className="font-mono">
          <span>2. Перед Вами з'явиться таблиця з заняттями — її можна вже використовувати</span>
          <img
            className="w-full max-w-2xl mx-auto mt-4 rounded"
            src={`${process.env.PUBLIC_URL}/instructions/5UiMChIpF.png`}
            alt="малюнок 2"
          />
        </p>
        <p className="font-mono">
          <span>
            3. Щоб змінити форму перегляду натисніть на{' '}
            <img
              className="inline"
              src={`${process.env.PUBLIC_URL}/instructions/5VOjL7NKG.png`}
              alt=""
            />{' '}
            зліва зверху Вашого екрану
          </span>
          <img
            className="w-full max-w-2xl mx-auto mt-4 rounded"
            src={`${process.env.PUBLIC_URL}/instructions/5VOTmRPhS.png`}
            alt="малюнок 3"
          />
        </p>
      </div>
    </div>
  )
}
